<template>
    <div class="container w-full block sm:px-2 md:p-0 px-2">
        <div class="sm:block md:flex block w-full">
            <div class="sm:w-full md:w-3/4 w-full block">
                <h1 class="text-3xl font-bold">{{ windowWidth > 600 ? 'About' : '' }} {{ data.Name }}</h1>
                <span class="block my-auto pr-2 mb-0" v-if="windowWidth < 600">{{ data.Address }}</span>
                <div class="flex w-full" v-if="data.Icons && windowWidth > 600">
                    <div class="flex mr-2" v-for="(item, n) in data.Icons" :key="n">
                        <b-icon
                            :icon="'account' || item.Icon"
                            size="is-small"
                            class="my-auto"
                        >
                        </b-icon>
                        <span class="ml-2" :class="windowWidth < 600 ? 'text-sm' : ''">{{ item.Name }}</span>
                    </div>
                </div>
                <div v-if="windowWidth > 600" v-html="data.Description"></div>
            </div>
            <div class="sm:p-0 md:pl-5 p-0 sm:w-full md:w-1/4 w-full block">
                <h1 class="text-3xl font-bold" v-if="windowWidth > 600">Location</h1>
                <div class="block w-full mb-0" v-if="windowWidth > 600">
                    <div id="map" class="map" style="height: 200px;"></div>
                    <span class="block my-auto pr-2 mb-0">{{ data.Address }}</span>
                    <!-- <div class="flex justify-center my-auto bg-blue-400 rounded-lg" style="width: 100px; height: 50px;">
                        <b-icon
                            class="self-center"
                            icon="map-marker"
                            size="is-medium"
                        >
                        </b-icon>
                    </div> -->
                </div>
                <div class="border border-solid border-grey-light border-t-0 border-l-0 border-r-0 my-2"></div>
                <b-collapse class="shadow-none" animation="slide" :open="openCollapse" @open="openCollapse = true">
                    <div slot="trigger" slot-scope="props" class="card-header shadow-none" role="button">
                        <div class="card-header-title p-1 font-normal">
                            <b-icon icon="clock-outline" size="is-medium"></b-icon>
                            <span class="ml-2">
                                Open Now {{ getOpeningTIme(data.OpeningHours) }}
                            </span>
                        </div>
                        <a class="card-header-icon p-1">
                            <b-icon
                                :icon="props.open ? 'menu-down' : 'menu-up'">
                            </b-icon>
                        </a>
                    </div>
                    <div class="card-content p-1">
                        <div class="content">
                            <div class="block w-full mb-1" :class="item.Day === getNameOfDay() ? 'font-bold' : ''" v-for="(item, n) in data.OpeningHours" :key="n">
                                <div class="flex w-full justify-between" v-if="item.Day === getNameOfDay()">
                                    <span>{{ item.Day }}</span>
                                    <span>{{ item.Time }}</span>
                                </div>
                                <div class="flex w-full justify-between" v-else>
                                    <span>{{ item.Day }}</span>
                                    <span>Close</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
                <div class="block w-full p-1" v-if="data.Icons && windowWidth < 600">
                    <div class="flex mb-2" v-for="(item, n) in data.Icons" :key="n">
                        <b-icon
                            :icon="'account-outline' || item.Icon"
                            size="is-medium"
                            class="my-auto"
                        >
                        </b-icon>
                        <span class="ml-2 my-auto">{{ item.Name }}</span>
                    </div>
                </div>
                <div class="border border-solid border-grey-light border-t-0 border-l-0 border-r-0 my-5" v-if="windowWidth < 600"></div>
                <p v-if="windowWidth < 600" v-html="data.Description">{{ data.Description }}</p>
            </div>
        </div>
        <div class="border border-solid border-grey-light border-t-0 border-l-0 border-r-0 my-2"></div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            openCollapse: false
        }
    },
    methods: {
        getNameOfDay () {
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            return days[new Date().getDay()]
        },
        getOpeningTIme (item) {
            var value = ''
            item.forEach(x => {
                if (x.Day === this.getNameOfDay()) value = x.Time
            })
            return value
        }
    },
    mounted () {
        console.log(this.getNameOfDay())
        var data = {
            Latitude: 1.1628328180832364,
            Longitude: 104.04505209595521,
            Name: 'Elite Center'
        }
        this.leafletMap(data)
    }
}
</script>
